(function(window, document){

    /*
     * 埋点2.0参数配置
     */
    function newAb (params) {
        var businessParams = {},
            ab2Url = '';

        location.host === "www.xin.com" ? ab2Url = 'ab.xin.com/hitlog.gif' : ab2Url = 'ab.test.xin.com/hitlog.gif';

        params.map(function(item, index){
            var arr = item.split('=');
            //添加判断--参数值为空的情况下，不往2.0里面传值。 
            if(arr[1]){
                businessParams[arr[0]] = arr[1];
            }
        })

        delete businessParams.ua;
        delete businessParams.from;
        delete businessParams.rf_seo;
        delete businessParams.rf_key;

        businessParams.pid = window.__ab2__ && window.__ab2__.pid ? window.__ab2__.pid : '';

        //借用之前打点中的获取地址栏参数方法，获取appid
        //如果地址栏存在app_id，则使用地址栏的值
        //如果地址栏不存在app_id，使用“u2_0”
        var appId = window._abq._getParam('app_id');
        if(!appId) {
            appId = "u2_0";
        }
        businessParams.app_id=appId;

        var config = {
            business:businessParams,
            getUrl:ab2Url,
            postUrl:'',
            success:function () {},
            failed:function () {},
            appVersion:'xinapp',
            uid:'XIN_UID',
            cityIdForUrl:'_?',
            xdid:'xdid',
            //这个参数暂时无效，被businessParams.app_id代替了
            appId:'app_id',
            net:'net',
            location:'location'
        };
        
        if(window.__ab2__ && window.__ab2__.sendAb2) {
            window.__ab2__.sendAb2(config,'get');
        }
    }

    function ABQueue(){
        this._init();
    };
    ABQueue.prototype = {
        constrcutor: ABQueue,
        UNKNOW: 'unknow',
        _objStr: Object.prototype.toString,
        _isArray: function(data){
            return data && this._objStr.call(data) == '[object Array]' ? true : false;
        },
        _beforeDomLoadedQueue: [],
        _debug: '',
        _isCollectPerfInfo: false,
        _aburl: ('https:' == document.location.protocol ? 'https://' : 'http://') + 'ab.xin.com/collect.gif?',
        _abposturl: ('https:' == document.location.protocol ? 'https://' : 'http://') + 'ab.xin.com/post.gif?',
        _init: function(){
            var that = this,
                abQueue = [],
                loadedEvent = function(e){
                    while(abQueue.length > 0){
                        that.push(abQueue.splice(0, 1));
                    }
                },
                onload = function(e){
                    loadedEvent(e);
                    if(window.performance && !that._isCollectPerfInfo) {
                        that._isCollectPerfInfo = true;
                        // if(window.performance && Math.random()<0.05 || that._getChannel() == 'performance'){
                        setTimeout(function(){
                            that._performance();
                        }, 3000);
                    }
                    //开启参数传递功能入口
                    //that.deliver();
                };
            if(that._isArray(window._abq)){
                var len = window._abq.length;

                for(var i = 0;i < len;i++){
                    abQueue.push(window._abq[i]);
                }
            }

            if(document.addEventListener){
                document.removeEventListener('DOMContentLoaded', loadedEvent, false);
                window.removeEventListener('load', onload, false);
                document.addEventListener('DOMContentLoaded', loadedEvent, false);
                window.addEventListener('load', onload, false);
            }else if(document.attachEvent){
                document.detachEvent('onreadystatechange', loadedEvent);
                window.detachEvent('onload', onload);
                document.attachEvent('onreadystatechange', loadedEvent);
                window.attachEvent('onload', onload);
            }

        },

        //传递指定的query变量
        deliver: function(){
            var that = this,

                //要传递的参数列表
                params = window.deliverParams || [],

                //key=value格式化完成的参数列表
                paramKV = [],
                $ = that._jQuery();

            //生成格式化的参数列表，先从页面url上取值，找不到的话用指定方法获取初始值
            for(var i=0;i<params.length;i++){
                paramKV.push(params[i] + '=' + (that._getParam(params[i]) || that._getParamValue(params[i])));
            }

            //绑定事件，在超链接跳转之前修改链接地址，追加或修改要传递的参数
            $ && $(document).on("mousedown touchstart", "a", function(){
                //已经处理过了，就不处理了。
                if($(this).attr("deliver") == '1'){
                    return;
                }
                var _href = this.href;

                if(!_href || /^(javascript\:|\#|tel\:)/.test(_href)){
                    return;
                }

                //外链不做处理
                if(/^https?\:\/\//.test(_href) && !/xin\.com\//ig.test(_href)){
                    return;
                }

                var href = _href.split("?"),
                    path = href[0].replace(/\#.*/g, ""),
                    _search = (href.length>1? href[1]: "").split("#"),
                    search = _search[0],
                    hash = _href.split("#")[1] || "";
                newObjSearch = {},
                    newArrSearch = [];

                search = search? search.split("&"): [];

                //把href上当前的参数加入队列
                for(var i=0,kv;i<search.length;i++){
                    kv = search[i].split("=");
                    newObjSearch[kv[0]] = kv[1];
                }

                //把要传递的参数加入队列，同名覆盖前面的
                for(var i=0,kv;i<paramKV.length;i++){
                    kv = paramKV[i].split("=");
                    newObjSearch[kv[0]] = kv[1];
                }

                //把队列里的参数转换到数组里
                for(var k in newObjSearch){
                    newArrSearch.push(k + "=" + newObjSearch[k]);
                }

                //拼接生成新的href
                href = path + (
                    newArrSearch.length>0? "?" + newArrSearch.join("&"): ""
                ) + (
                    hash? "#" + hash: ""
                );
                $(this).attr({
                    "href": href,
                    "deliver": 1
                });
            });
        },

        //获取特定query变量的初始值
        _getParamValue: function(param){
            var that = this;
            switch(param){
                case "abtest":
                    return that._getABtest();
                default:
                    return '';
            }
        },

        //绑定事件，暂不兼容ie8，不要使用。
        _bind: function(dom, type, fn, select){
            var callback = function(event){
                event = event || window.event;
                for(var i=0, dom;i<event.path.length;i++){
                    dom = event.path[i];
                    if((dom.nodeName || dom.tagName).toUpperCase() == select.toUpperCase()){
                        fn.call(dom, event);
                        break;
                    }
                }
            };
            if(document.addEventListener){
                dom.addEventListener(type, callback, false);
            }else if(document.attachEvent){
                dom.attachEvent(type, callback);
            }
        },

        //返回jQuery对象
        _jQuery: function(){
            if(window.jQuery){
                return window.jQuery;
            }else if(window.Zepto){
                return window.Zepto;
            }
            return undefined;
        },
        _setCookie: function(name, value, domain, path, expires, secure){
            document.cookie = [
                name,
                '=',
                value,
                ';',
                'domain',
                '=',
                domain,
                ';',
                'path',
                '=',
                path,
                ';',
                'expires',
                '=',
                expires,
                ';',
                (secure ? 'secure' : ''),
                ';'
            ].join('');
        },
        _getCookie: function(name){
            var reg = new RegExp(name + '\s*=\s*([^\;]+)\;*'),
                result = reg.exec(document.cookie);

            return result && result.length > 1 ? result[1] : this.UNKNOW;
        },
        _getGuid: function(){
            var guid = '';

            for (var i = 1; i <= 32; i++){
                var n = Math.floor(Math.random() * 16.0).toString(16);

                guid += n;

                if((i == 8) || (i == 12) || (i == 16) || (i == 20)){
                    guid += '-';
                }
            }

            return guid;
        },
        _getUniqueId: function(key){
            var that = this,
                key = 'XIN_UID_CK',
                uniqueId = that._getCookie(key);
            if(uniqueId == that.UNKNOW){
                that._setCookie(key, that._getGuid(), 'xin.com', '/', (new Date('2037/12/31 23:59:59')).toUTCString());

                uniqueId = that._getCookie(key);
            }

            return uniqueId;
        },

        /*获取ABtest的结果*/
        _getABtest: function(B_Chance){
            var that = this,
                test = "",
                guid = that._getUniqueId(),
                abNum = parseInt(guid.split("-")[0], 16);
            B_Chance = B_Chance || 0.3;
            test = (abNum / 10000 )%1 <= B_Chance? "B": "A";
            return test;
        },


        _getEvent: function(params){
            var splitor = '/';
            return splitor + params.slice(1).join(splitor);
        },
        _getFrom: function(){
            var ua = window.navigator.userAgent,
                ua = ua.toUpperCase(),
                isApp = /XINAPP*[\/,=]*/i.test(ua),
                isAndroid = /android/ig.test(ua),
                isIOS = /iphone/ig.test(ua),
                isIPAD = /(iPad).*OS\s([\d_]+)/ig.test(ua),
                from = '',
                matchFrom = ua.match(/XINAPP\[(\w+)\]/i);

            if(isApp){
                if(matchFrom && matchFrom[1]){
                    from = matchFrom[1];
                }else if(isAndroid){
                    from = 'm-android';
                }else if(isIOS || isIPAD){
                    from = 'm-ios';
                }
            }else{
                from = isAndroid || isIOS || isIPAD ? 'm': 'www';
            }

            return from;
        },
        _getAppVersion: function(){
            var uav = window.navigator.userAgent;
            uav = uav.toUpperCase();
            var version = /XINAPP*[\/,=]\s*([^\s]+)/.exec(uav);

            return version && version.length > 1 ? version[1] : '';
        },
        _getUserId: function(){
            var that = this,
                userId = that._getCookie('XIN_UID');

            return userId != that.UNKNOW ? userId : '';
        },
        _getSeoSource: function(url) {
            var that = this,
                key = 'SEO_SOURCE';
                sourceName = that._getCookie(key);
            if (that._getParam("channel")) {
                return;
            }
            if ((sourceName == that.UNKNOW && url != "") || (that._checkDomain(that._getSource(url)) && that._getSource(url) != that._getSource(sourceName) && url!="")) {
                var exp = new Date();
                exp.setTime(exp.getTime() + 60 * 1000 * 60 * 24);
                that._setCookie(key,url,'xin.com', '/', exp.toUTCString());
                sourceName = that._getCookie(key);
            }
            return sourceName;
        },
        _getCityId: function(){
            var that = this,
                cityData = that._getCookie('XIN_LOCATION_CITY');

            return cityData != that.UNKNOW ? (JSON.parse(decodeURIComponent(cityData))).cityid : '';
        },
        _getSource: function(url){
            var result = /.?:\/\/([^\/]+)\/.*/.exec(url);
            return result && result.length > 1 ? result[1] : '';
        },
        _getToken: function () {
            var tokenForUrl = this._getParam('optoken');
            var tokenForwin = window.__json4fe__;
            return tokenForUrl ? tokenForUrl : tokenForwin && tokenForwin.optoken ? tokenForwin.optoken : '';
        },
        _getChannel: function(){
            var channel = /channel\s*=\s*([^&]+)&?/.exec(decodeURIComponent(window.location.search));

            return channel && channel.length > 1 ? encodeURIComponent(channel[1]) : '';
        },
        _getSeoRef: function(url){
            var that = this,
                key = 'SEO_REF',
                uniqueId = that._getCookie(key);
            var items='';
            if(that._getParam("channel")){
                // items = "seo_sm";
                return items;
            }
            if(that._getParam("referer")){
                url = that._getParam("referer");
            }
            var pattern = new RegExp("[?&]referer\=([^&]+)", "g");
            var matcher = pattern.exec(url);
            if(matcher !=null){
                try{
                    url = decodeURIComponent(decodeURIComponent(matcher[1]));
                }catch(e){
                    try{
                        url = decodeURIComponent(matcher[1]);
                    }catch(e){
                        url = matcher[1];
                    }
                }
            }

            if(uniqueId == that.UNKNOW && url!="" || that._checkDomain(that._getSource(url)) && that._getSource(url) != that._getSource(uniqueId) && url!=""){
                var exp = new Date();
                exp.setTime(exp.getTime() + 60 * 1000 * 60 * 24);
                that._setCookie(key,url,'xin.com', '/', exp.toUTCString());
                uniqueId = that._getCookie(key);
            }
            if(that._checkDomain(that._getSource(uniqueId)) && url!=''){
                var domain = that._getSource(uniqueId);
                domain = domain.split(".");
                items = "seo_"+domain[1];
            }

            return items;
        },
        _getSeoKey:function(url){
            var that = this,
                key = 'SEO_KEY',
                uniqueId = that._getCookie(key),
                item='';
            if(that._getParam("referer")){
                url = that._getParam("referer");
            }
            var pattern = new RegExp("[?&]referer\=([^&]+)", "g");
            var matcher = pattern.exec(url);
            if(matcher !=null){
                try{
                    url = decodeURIComponent(decodeURIComponent(matcher[1]));
                }catch(e){
                    try{
                        url = decodeURIComponent(matcher[1]);
                    }catch(e){
                        url = matcher[1];
                    }
                }
            }
            var keyd = ['word','wd','q','keyword','query'];
            for(var i=0; i<keyd.length;i++){
                var pattern = new RegExp("[?&]"+keyd[i]+"\=([^&]+)", "g");
                var matcher = pattern.exec(url);
                if(matcher !=null){
                    try{
                        item = decodeURIComponent(decodeURIComponent(matcher[1]));
                    }catch(e){
                        try{
                            item = decodeURIComponent(matcher[1]);
                        }catch(e){
                            item = matcher[1];
                        }
                    }
                }
            }
            if(uniqueId == that.UNKNOW && item !="" || uniqueId != that.UNKNOW && item!=""){
                var exp = new Date();
                exp.setTime(exp.getTime() + 60 * 1000 * 60 * 24);
                that._setCookie(key,item,'xin.com', '/', exp.toUTCString());
                item = that._getCookie(key);
            }
            if(uniqueId != that.UNKNOW && item==''){
                item = uniqueId;
            }
            return item;
        },
        _checkDomain:function(name){
            var domain = ['www.xin.com','che.xin.com','m.xin.com','che.m.xin.com'];
            for(var i=0; i<domain.length; i++){
                if(domain[i]==name){
                    return false;
                }
            }
            return true;
        },
        _getParam:function(name){
            var search = document.location.search;
            var pattern = new RegExp("[?&]"+name+"\=([^&]+)", "g");
            var matcher = pattern.exec(search);
            var items = null;
            if(null != matcher){
                try{
                    items = decodeURIComponent(decodeURIComponent(matcher[1]));
                }catch(e){
                    try{
                        items = decodeURIComponent(matcher[1]);
                    }catch(e){
                        items = matcher[1];
                    }
                }
            }
            return items;
        },
        _isEnabledCookie: function(){
            return window.navigator.cookieEnabled ? '1' : '0';
        },
        _isSupportLocalStorage: function(){
            return window.localStorage ? '1' : '0';
        },
        _getLanguage: function(){
            return window.navigator.language || window.navigator.browserLanguage || window.navigator.systemLanguage || window.navigator.userLanguage || '';
        },
        _getColorDepth: function(){
            return window.screen.colorDepth || 0;
        },
        _getBaseParams: function(){
            var that = this,
                params = [];

            params.push('cid=' + that._getUniqueId());
            //params.push('ip=0');
            params.push('from=' + that._getFrom());
            params.push('app=' + that._getAppVersion());
            params.push('net=');
            params.push('ua=' + window.navigator.userAgent);
            params.push('sc=' + window.screen.width + '*' + window.screen.height);
            params.push('ts=' + (new Date()).getTime());
            if(window.pageid != undefined){
                params.push('pageid=' + window.pageid);
            }

            var abversion = that._getParam('abversion');
            params.push('abversion=' + (abversion || ''));
            params.push('keywordid=' + (that._getParam('keywordid') || ""));
            params.push('creative=' + (that._getParam('creative') || ""));
            params.push('mediaid=' + (that._getParam('mediaid') || ""));
            that._getToken() ? params.push('optoken=' + that._getToken()) : null;
            // if(window.ABtest == true || window.){
            //     params.push('ABtest=' + (that._getParam("abtest") || that._getABtest()).toUpperCase());
            // }

            return params;
        },
        _getTrackParams: function(orginParams){
            var that = this,
                params = [];

            params.push('type=c');
            params.push('ev=' + encodeURIComponent(that._getEvent(orginParams)));
            //params.push('pid=0');
            params.push('uid=' + that._getUserId());
            params.push('cityid=' + that._getCityId());
            params.push('url=' + encodeURIComponent(document.location.href));
            params.push('ref=' + encodeURIComponent(document.referrer));
            //params.push('source=' + that._getSource(document.referrer));
            params.push('source=' + that._getSeoSource(document.referrer));
            params.push('channel=' + that._getChannel());
            params.push('rf_seo=' + that._getClickSeoRef());
            params.push('rf_key=' + encodeURIComponent(that._getClickSeoKey()));

            params = params.concat(that._getBaseParams());


            return params;
        },
        _getClickSeoRef:function(){
            var that = this,
                uniqueId,
                items="";
            uniqueId = that._getCookie("SEO_REF");
            if(uniqueId != that.UNKNOW && that._checkDomain(that._getSource(uniqueId))){
                var domain = that._getSource(uniqueId);
                domain = domain.split(".");
                items = "seo_"+domain[1];
            }
            return items;
        },
        _getClickSeoKey:function(){
            var that = this,
                uniqueId,
                item ="";
            uniqueId = that._getCookie("SEO_KEY");
            if(uniqueId != that.UNKNOW){
                item = uniqueId;
            }
            return item;
        },
        _getPageviewParams: function(orginParams){
            var that = this,
                params = [];

            params.push('type=w');
            //params.push('pid=0');
            params.push('uid=' + that._getUserId());
            params.push('cityid=' + that._getCityId());
            params.push('url=' + encodeURIComponent(document.location.href));
            params.push('ref=' + encodeURIComponent(document.referrer));
            //params.push('source=' + that._getSource(document.referrer));
            params.push('source=' + that._getSeoSource(document.referrer));
            params.push('channel=' + that._getChannel());
            params.push('rf_seo=' + that._getSeoRef(document.referrer));
            params.push('rf_key=' + encodeURIComponent(that._getSeoKey(document.referrer)));
            params = params.concat(that._getBaseParams());
            return params;
        },
        _getLoadviewParams: function(orginParams){
            var that = this,
                params = [];

            params.push('type=e');
            //params.push('pid=0');
            params.push('uid=' + that._getUserId());
            params.push('cityid=' + that._getCityId());
            params.push('url=' + encodeURIComponent(document.location.href));
            params.push('ref=' + encodeURIComponent(document.referrer));
            //params.push('source=' + that._getSource(document.referrer));
            params.push('source=' + that._getSeoSource(document.referrer));
            params.push('channel=' + that._getChannel());
            params.push('rf_seo=' + that._getSeoRef(document.referrer));
            params.push('rf_key=' + encodeURIComponent(that._getSeoKey(document.referrer)));
            params = params.concat(that._getBaseParams());

            if(window.biAddAttributes != undefined){
                params.push('pl=' + window.biAddAttributes.pl);
                var va="";
                for(key in window.biAddAttributes.ds){
                    va+= key+"#"+window.biAddAttributes.ds[key]+ ",";
                }
                va=va.substring(0,va.length-1);
                params.push('ds=' + encodeURIComponent(va));
            }
            if(orginParams!=undefined || orginParams !="" || orginParams!=null){
                if(orginParams.pl!=undefined && orginParams.ds!=undefined){
                    params.push('pl=' + encodeURIComponent(orginParams.pl));
                    params.push('ds=' + encodeURIComponent(orginParams.ds));
                }
            }

            return params;
        },
        _getPerformanceParams: function(orginParams){
            var that = this,
                params = [];

            params.push('type=p');
            params.push('ce=' + that._isEnabledCookie());
            params.push('ls=' + that._isSupportLocalStorage());
            params.push('lg=' + that._getLanguage());
            params.push('cd=' + that._getColorDepth());
            params.push('pid=' + that._getPageId());
            params.push('source=' + that._getSeoSource(document.referrer));

            if(window.performance){
                var perf = window.performance;
                if(perf.navigation){
                    params.push('nt=' + perf.navigation.type);
                    params.push('nr=' + perf.navigation.redirectCount);
                }

                if(perf.timing){
                    params.push('pce=' + perf.timing.connectEnd);
                    params.push('pcs=' + perf.timing.connectStart);
                    params.push('pdc=' + perf.timing.domComplete);
                    params.push('pdclee=' + perf.timing.domContentLoadedEventEnd);
                    params.push('pdcles=' + perf.timing.domContentLoadedEventStart);
                    params.push('pdi=' + perf.timing.domInteractive);
                    params.push('pdl=' + perf.timing.domLoading);
                    params.push('pdle=' + perf.timing.domainLookupEnd);
                    params.push('pdls=' + perf.timing.domainLookupStart);
                    params.push('pfs=' + perf.timing.fetchStart);
                    params.push('plee=' + perf.timing.loadEventEnd);
                    params.push('ples=' + perf.timing.loadEventStart);
                    params.push('pns=' + perf.timing.navigationStart);
                    params.push('pre=' + perf.timing.redirectEnd);
                    params.push('prs=' + perf.timing.redirectStart);
                    params.push('preqs=' + perf.timing.requestStart);
                    params.push('prese=' + perf.timing.responseEnd);
                    params.push('press=' + perf.timing.responseStart);
                    params.push('pscs=' + perf.timing.secureConnectionStart);
                    params.push('puee=' + perf.timing.unloadEventEnd);
                    params.push('pues=' + perf.timing.unloadEventStart);
                    // 新增更多参数 by lishuliang
                    var readyStart = perf.timing.fetchStart - perf.timing.navigationStart;
                    var redirectTime = perf.timing.redirectEnd  - perf.timing.redirectStart;
                    var appcacheTime = perf.timing.domainLookupStart  - perf.timing.fetchStart;
                    var unloadEventTime = perf.timing.unloadEventEnd - perf.timing.unloadEventStart;
                    var lookupDomainTime = perf.timing.domainLookupEnd - perf.timing.domainLookupStart;
                    var connectTime = perf.timing.connectEnd - perf.timing.connectStart;
                    var requestTime = perf.timing.responseEnd - perf.timing.fetchStart;
                    var initDomTreeTime = perf.timing.domInteractive - perf.timing.responseEnd;
                    var domReadyTime = perf.timing.domContentLoadedEventEnd - perf.timing.responseEnd;
                    var loadTime = perf.timing.domComplete - perf.timing.fetchStart;
                    // dom contentLoadevent 回调函数执行时间
                    var domCLEFuncTime = perf.timing.domContentLoadedEventEnd - perf.timing.omContentLoadedEventStart

                    params.push('ready='+readyStart);
                    params.push('redirect='+redirectTime);
                    params.push('cache='+appcacheTime);
                    params.push('unload='+redirectTime);
                    params.push('dns='+lookupDomainTime);
                    params.push('tcp='+connectTime);
                    params.push('request='+requestTime);
                    params.push('dom='+domReadyTime);
                    params.push('load='+loadTime);

                    //添加白屏时间
                    if(window.__json4fe__ && window.__json4fe__.performance && window.__json4fe__.performance.whiteScreen)
                    {
                        var whiteTime = window.__json4fe__.performance.whiteScreen - perf.timing.navigationStart;
                        params.push('white=' + whiteTime);
                    }
                    //获取主机名+端口
                    params.push('hostname='+location.hostname);
                    //获取路径命
                    params.push('uri='+location.pathname);
                    //获取页面title
                    params.push('title='+document.title);
                    //获取前一个页面的url
                    var referrer = document.referrer;
                    if(referrer && referrer.indexOf('?') != -1){
                        referrer = referrer.replace(/=/g,":");
                    }

                    params.push('refer='+referrer);
                    //获取页面url，不带查询字符串
                    params.push('url='+window.location.href.split("?")[0])
                    var search = location.search;
                    if(search && search.indexOf('?') != -1){
                        search = search.slice(1).split('/').toString().replace(/=/g,":")
                    }
                    //获取查询字符串，替换=号为：
                    params.push('params='+search);
                }
                if(perf.memory){
                    params.push('pjshsl=' + perf.memory.jsHeapSizeLimit);
                    params.push('ptjshs=' + perf.memory.totalJSHeapSize);
                    params.push('pujshs=' + perf.memory.usedJSHeapSize);
                }
                // 不收集页面其他资源的性能参数
                // params.push('entries=' + that._getEntriesParams());
            }

            params = params.concat(that._getBaseParams());

            return params;
        },
        _getEntriesParams: function(){
            var that = this,
                params = [];
            if(window.performance && window.performance.getEntries){
                var perf = window.performance.getEntries();
                for (var i = 0; i < perf.length; i++) {
                    params.push(that._getEntrie(perf[i]));
                }
            }
            return params.join(";");
        },
        _getEntrie: function(entrie){
            var that = this,
                fixed = that._toFixed,
                newObj = [];
            entrie.connectEnd && (newObj.push('ece:' + fixed(entrie.connectEnd)));
            entrie.connectStart && (newObj.push('ecs:' + fixed(entrie.connectStart)));
            entrie.decodedBodySize && (newObj.push('edbs:' + fixed(entrie.decodedBodySize)));
            entrie.domainLookupEnd && (newObj.push('edle:' + fixed(entrie.domainLookupEnd)));
            entrie.domainLookupStart && (newObj.push('edls:' + fixed(entrie.domainLookupStart)));
            entrie.duration && (newObj.push('ed:' + fixed(entrie.duration)));
            entrie.encodedBodySize && (newObj.push('eebs:' + fixed(entrie.encodedBodySize)));
            entrie.entryType && (newObj.push('eet:' + entrie.entryType));
            entrie.fetchStart && (newObj.push('efs:' + fixed(entrie.fetchStart)));
            entrie.initiatorType && (newObj.push('eit:' + entrie.initiatorType));
            entrie.name && (newObj.push('en:' + entrie.name.replace(/^http(s?)\:\/\/([sc][1-6]?)\.xinstatic\.com\//i, "$1!$2//")));
            entrie.redirectEnd && (newObj.push('erde:' + fixed(entrie.redirectEnd)));
            entrie.redirectStart && (newObj.push('erds:' + fixed(entrie.redirectStart)));
            entrie.requestStart && (newObj.push('erqs:' + fixed(entrie.requestStart)));
            entrie.responseEnd && (newObj.push('erpe:' + fixed(entrie.responseEnd)));
            entrie.responseStart && (newObj.push('erps:' + fixed(entrie.responseStart)));
            entrie.secureConnectionStart && (newObj.push('escs:' + fixed(entrie.secureConnectionStart)));
            entrie.startTime && (newObj.push('est:' + fixed(entrie.startTime)));
            entrie.transferSize && (newObj.push('ets:' + fixed(entrie.transferSize)));
            entrie.workerStart && (newObj.push('ews:' + fixed(entrie.workerStart)));

            return newObj.join(",");
        },
        _toFixed: function(num){
            return num.toFixed(3)
        },
        _getCollectUrl: function(params){
            var that = this;

            return (that._debugurl ? that._debugurl : that._aburl) + params.join('&');
        },
        _collect: function(params){
            var img = new Image;

            img.style.display = 'none';
            img.src = this._getCollectUrl(params);

            document.body.appendChild(img);
            newAb(params);
        },
        _collectPost: function(params){
            var that = this,
                xhr = new XMLHttpRequest();
            xhr.open("POST", (that._debugurl ? that._debugurl : that._abposturl));
            xhr.send(params.join("&"));
        },
        _debug: function(params){
            if(params.length < 2) return;

            this._debugurl = params[1];
        },
        _track: function(params){

            var that = this;

            that._collect(that._getTrackParams(params));
        },
        _pageview: function(params){
            var that = this;
            if(document.getElementById("ad_home_adver_top")){
                that._collect(that._getLoadviewParams(params));
            }
            that._collect(that._getPageviewParams(params));
        },
        _performance: function(params){

            this._collect(this._getPerformanceParams(params));
        },
        push: function(params){
            var that = this;

            if(!that._isArray(params)) return;

            if(that._isArray(params[0])){
                for(var i = 0 ; i<params.length;i++){
                    that.push(params[i]);
                }
                return false;
            }

            var method = that[params[0]];

            if(!method) return;

            method.call(that, params);
        },
        _eventReq:function(params){
            var that = this;
            that._collect(that._getLoadviewParams(params[1]));
        },
        _getPageId: function(){
            return window.PAGE_ID || window.location.href.split("?")[0];
        }
    };
    window._abq = new ABQueue;
})(window, document);
